.lng-modal {
  position: fixed;
  visibility: hidden;
  overflow-y: auto;
  margin: 0 auto;
  max-width: 600px;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
  transform: translateY(100%);
  transition: transform 0.3s ease, visibility 0.3s ease;
  color: #fff;
  background-color: #000;
  padding-top: env(safe-area-inset-top);

  .header {
    background-color: #000;
    position: sticky;
    top: 0;
    bottom: 0;
    height: 60px;
    padding: 0 20px;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      font-size: 14px;
      font-weight: 400;
      border-bottom: 1px solid #252629;

      .close {
        cursor: pointer;
        width: 24px;
      }
    }
  }

  .content {
    padding: 16px;

    .modal-list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .modal-item {
      cursor: pointer;
      padding: 12px 16px;
      font-size: 14px;
      border-radius: 4px;
    }

    .modal-item:not(:last-child) {
      margin-bottom: 4px;
    }

    .modal-item:hover,
    .modal-item.active {
      background-color: #252629;
    }
  }
}

.lng-modal.visible {
  visibility: visible;
  transform: translateY(0);
}
