.app {
  margin: 0 auto;
  max-width: 600px;
  background-color: #000;
  padding-top: env(safe-area-inset-top);

  .global-banner {
    width: auto;
    position: sticky;
    display: flex;
    justify-content: flex-end;
    top: 0;
    z-index: 99;
    background-color: #000;
    padding: 13px 16px;

    .global-icon {
      cursor: pointer;
      user-select: none;
      -webkit-user-drag: none;
      width: 24px;
      height: 24px;
    }
  }

  .container {
    color: #fff;
    width: auto;
    padding: 3px 16px 16px 16px;

    .main {
      padding-bottom: 16px;

      .title {
        text-align: center;
        font-style: normal;
        font-size: 28px;
        font-weight: 900;
        line-height: 140%;
      }

      .subtitle {
        text-align: center;
        font-style: normal;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .main-img {
        user-select: none;
        -webkit-user-drag: none;
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    .feature {
      .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
        color: #f4f5f7;
      }

      .content {
        padding: 16px 0;

        .feat {
          font-style: normal;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          color: #fff;
        }

        .desc {
          font-style: normal;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
          color: #8e8e92;
        }
      }
    }

    .signup {
      padding-top: 34px;

      .title {
        text-align: center;
        font-style: normal;
        font-size: 28px;
        font-weight: 900;
        line-height: 120%;
        margin-bottom: 32px;
      }

      .signup-img {
        user-select: none;
        -webkit-user-drag: none;
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    .saving {
      padding-top: 50px;
      padding-bottom: 50px;

      .subtitle {
        text-align: center;
        font-style: normal;
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;
      }

      .title {
        text-align: center;
        font-style: normal;
        font-size: 28px;
        font-weight: 900;
        line-height: 120%;
        margin: 8px 0;
      }

      .desc {
        text-align: center;
        font-style: normal;
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;
      }

      .saving-img {
        user-select: none;
        -webkit-user-drag: none;
        margin-top: 24px;
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
  }

  .download-banner {
    width: auto;
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    bottom: 0;
    z-index: 99;
    height: 58px;
    padding: 0 16px;
    padding-bottom: env(safe-area-inset-bottom);

    .title-wrapper {
      display: flex;
      align-items: center;

      .app-icon {
        user-select: none;
        -webkit-user-drag: none;
        width: 36px;
        height: 36px;
        margin-right: 16px;
      }

      .title {
        color: #f4f5f7;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        margin-bottom: 4px;
      }

      .sub-title {
        color: #8e8e92;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
      }
    }

    .button {
      position: sticky;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      bottom: 0;
      width: 80px;
      height: 32px;
      background-color: #f4f5f7;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  }
}
