@font-face {
  font-family: "Switzer";
  font-weight: 100;
  src: url("./assets/fonts/Switzer-Thin.otf") format("opentype");
}

@font-face {
  font-family: "Switzer";
  font-weight: 200;
  src: url("./assets/fonts/Switzer-Extralight.otf") format("opentype");
}

@font-face {
  font-family: "Switzer";
  font-weight: 300;
  src: url("./assets/fonts/Switzer-Light.otf") format("opentype");
}

@font-face {
  font-family: "Switzer";
  font-weight: 400;
  src: url("./assets/fonts/Switzer-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Switzer";
  font-weight: 500;
  src: url("./assets/fonts/Switzer-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Switzer";
  font-weight: 600;
  src: url("./assets/fonts/Switzer-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "Switzer";
  font-weight: 700;
  src: url("./assets/fonts/Switzer-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Switzer";
  font-weight: 800;
  src: url("./assets/fonts/Switzer-Extrabold.otf") format("opentype");
}

@font-face {
  font-family: "Switzer";
  font-weight: 900;
  src: url("./assets/fonts/Switzer-Black.otf") format("opentype");
}
