.toast {
  position: fixed;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%);
  background-color: #252629;
  color: #fff;
  padding: 14px 30px;
  border-radius: 6px;
  font-size: 14px;
  z-index: 999;
  font-weight: 400;
}
